// --------------------------------------------------
//   Imports
// --------------------------------------------------

// React
import { useState, useEffect, useRef } from 'react';

// Material UI
import {
  Box,
  LinearProgress,
} from "@mui/material";

// API
import { createActivity } from '../../api/activity-service';

const activityTypes = Object.freeze({
  USER_COMPLETED_VIDEO:  'USER_COMPLETED_VIDEO',
})

// --------------------------------------------------
//   Component
// --------------------------------------------------

export default function PracticeVideo({ video, videoRef, useCaptions }) {

  // Track video progress
  const [ progress, setProgress ] = useState(0);
  useEffect(() => {
    if (!video || !videoRef.current) return;

    // Save current video ref, so callbacks can reference the same one even after it changes
    const $videoElement = videoRef.current;

    // Update the progress bar
    $videoElement.addEventListener("timeupdate", () => {
      setProgress($videoElement.currentTime / $videoElement.duration * 100)
    })

    // Create a new activity event when the video completes
    const create = () => {
      createActivity(activityTypes.USER_COMPLETED_VIDEO, video.id)
    }
    $videoElement.addEventListener('ended', create)
    return () => {
      $videoElement.removeEventListener('ended', create)
    }
    
  }, [video])

  // If a captions URL is provided, create a track object
  const [ $ccTrack, setCCTrack ] = useState(null)
  useEffect(() => {
    if (video && video.cc_url) {
      setCCTrack(
        <track
          label   = "English"
          kind    = "subtitles"
          srcLang = "en"
          src     =  { video.cc_url }
          default
        />
      )
    }
  }, [video])

  
  return (
    <Box
      gap={0} backgroundColor="black" width="100%" height="100%"
      display="flex" flexDirection="column" justifyContent="center"
      position="absolute"
    >
      {
        video
          ? (
            <video
              ref={ videoRef }
              loop={false} playsInline="playsinline" preload="metadata"
              style={{width: "100%", height: "calc(100% - 12px)", objectFit: "contain"}}
              crossOrigin="anonymous"
            >
              <source src={ `${video.url}#t=1` } type="video/mp4" />
              { useCaptions ? $ccTrack : null }
            </video>
          )
          : (
            <Box width="100%" height="100%" backgroundColor="black" />
          )
      }
      <LinearProgress
        variant="determinate"
        color="secondary"
        value={progress}
        style={{"height": "12px", marginTop: "-3px"}}
        sx={{
          backgroundColor: '#4447ad',
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#b39fe7',
            transition: 'none'
          }
        }}
      />
    </Box>
  )
}
